<template>
  <BModal
    id="modalImage"
    ref="modalImage"
    hide-footer
    scrollable
    size="xl"
    hide-header
    :centered="true"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >
    <BRow class="justify-end mb-1">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
        alt="Komerce"
        class="cursor-pointer"
        @click="$bvModal.hide('modalImage')"
      >
    </BRow>
    <BCarousel
      v-if="data.length > 0"
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000"
      fade
      indicators
      controls
    >
      <BCarouselSlide
        v-for="(item, idx) in data"
        :key="idx"
        class="fluid"
      >
        <template #img>
          <img
            :src="item.path"
            :alt="`bukti-${idx}`"
            class="contain h-[85vh] mx-auto"
          >
        </template>
      </BCarouselSlide>
    </BCarousel>
  </BModal>
</template>

<script>
import {
  BModal, BCarousel, BCarouselSlide, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BModal, BCarousel, BCarouselSlide, BRow,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style>
  .carousel-control-next-icon {
    background-image: url(https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/arrow-circle-right.svg) !important;
  }

  .carousel-control-prev-icon {
    background-image: url(https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/arrow-circle-left.svg) !important;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 92px !important;
    width: 92px !important;
  }
</style>
