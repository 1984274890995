<template>
  <BModal
    id="modalApprove"
    ref="modalApprove"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <div class="text-center my-1">
      <h4 class="text-black text-xl font-bold mb-1">
        Setujui
      </h4>
      <div style="width: 80%; margin: auto;">
        <p style="color: #626262">
          Saldo user akan bertambah ketika kamu merubah status ke <span class="text-black font-bold">Disetujui</span>
        </p>
      </div>
      <div style="margin-top: 20px;">
        <BButton
          size="md"
          variant="outline-primary"
          class="mr-1"
          style="width: 160.5px"
          @click="closeModal"
        >
          Batal
        </BButton>
        <BButton
          size="md"
          variant="primary"
          style="width: 160.5px"
          @click="handleApprove"
        >
          <BSpinner
            v-if="loading"
            small
            variant="light"
          />
          Setujui
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { BModal, BButton, BSpinner } from 'bootstrap-vue'
import { required } from 'vee-validate/dist/rules'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komshipAxiosIns } from '@/libs/axios'

export default {
  components: { BModal, BButton, BSpinner },
  props: {
    ticketId: {
      type: Number,
      default: required,
    },
    getData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      centered: true,
      loading: false,
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modalApprove')
    },
    async handleApprove() {
      this.loading = true
      const formData = new FormData()
      formData.append('status_claim', 1)
      const url = `/v1/ticket-admin/handling-claim-retur/${this.ticketId}`
      await komshipAxiosIns
        .post(url, formData)
        .then(() => {
          this.$toast_success({ message: 'Berhasil menyetujui claim retur' })
          this.loading = false
          this.getData()
          this.$bvModal.hide('modalApprove')
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal menyetujui claim retur' })
          this.$bvModal.hide('modalApprove')
          this.getData()
          this.loading = false
        })
    },
  },
}
</script>
