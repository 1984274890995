<template>
  <BModal
    id="modal-download-claim-retur"
    ref="modal-download-claim-retur"
    hide-footer
    hide-header
    centered
    size="lg"
    body-class="modal-download-body"
    dialog-class="dialog-download-claim-retur"
  >
    <div class="w-full flex flex-col gap-[16px] text-center text-[#222222]">
      <h3 class="font-semibold text-[24px]">
        Download Data Claim Retur
      </h3>
      <p class="mb-0 font-medium">
        Data claim retur siap diambil. Yuk gunakan sebaik mungkin untuk memaksimalkan koordinasi dengan tim ekspedisi agar pengajuan claim retur dapat segera direview.
      </p>

      <div class="w-full">
        <BTable
          :items="items"
          :fields="fields"
          bordered
          responsive
        >
          <template #cell(periode)>
            <DateRangePicker
              ref="picker"
              v-model="dateRange"
              :locale-data="locale"
              :ranges="ranges"
              opens="center"
              class="w-[260px]"
              append-to-body
            >
              <template
                v-slot:input="picker"
              >
                <div class="flex justify-between w-full">
                  <span class="text-black truncate ... leading-[1.9]">{{ picker.rangeText }}</span>

                  <b-img
                    src="https://storage.googleapis.com/komerce/assets/icons/date-picker-icon.svg"
                    alt="date-picker"
                  />
                </div>
              </template>
            </DateRangePicker>
          </template>

          <template #cell(expedisi)>
            <b-form-checkbox-group
              v-model="expedisiSelected"
              :options="shippingItems"
              :class="idx === 0 ? '' : 'my-[5px]'"
              :value="item"
              class="flex flex-col space-y-4"
              stacked
            />
          </template>

          <template #cell(action)>
            <b-form-checkbox-group
              v-model="actionSelected"
              :options="actionOptions"
              name="action"
              class="flex flex-col space-y-4"
              stacked
            />
          </template>
        </BTable>
      </div>

      <div class="w-full flex justify-end">
        <div class="flex gap-[8px]">
          <BButton
            variant="outline-primary"
            class="button-download-claim"
            @click="handleBatal"
          >
            Batal
          </BButton>
          <BButton
            class="button-download-claim"
            :disabled="loading"
            variant="primary"
            @click="handleDownload"
          >
            <BSpinner
              v-if="loading"
              small
              class="mr-1"
            />
            Download</BButton>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import {
  BModal, BTable, BButton, BSpinner,
} from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  today,
  last7,
  last30,
  firstDateOfMonth,
  komshipDate,
} from '@/store/helpers'

const tdClass = 'text-start align-top table-download-claim-cell'
const thClass = 'text-start table-download-claim-cell'

export default {
  components: {
    BModal, BTable, DateRangePicker, BButton, BSpinner,
  },
  props: {
    expeditionsList: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      items: [
        { periode: '', expedisi: '', action: '' },
      ],
      fields: [
        {
          key: 'periode', label: 'Periode', tdClass, thClass,
        },
        {
          key: 'expedisi', label: 'Expedisi', tdClass, thClass,
        },
        {
          key: 'action', label: 'Action', tdClass, thClass,
        },
      ],
      dateRange: {
        startDate: last7,
        endDate: today,
      },
      ranges: {
        'Semua ': [komshipDate, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      expedisiSelected: [],
      shippingItems: this.expeditionsList.map(item => item.shipping_name),

      actionSelected: ['Nunggu Review'],
      actionOptions: [
        { text: 'Nunggu Review', value: 'Nunggu Review' },
        { text: 'Disetujui', value: 'Disetujui' },
        { text: 'Ditolak', value: 'Ditolak' },
      ],

      loading: false,
    }
  },
  computed: {
    params() {
      const params = {}

      if (this.expedisiSelected.length > 0) {
        params.shipping = this.expedisiSelected.join(',')
      }

      if (this.actionSelected.length > 0) {
        params.status_claim = this.actionSelected.join(',')
      }

      if (this.dateRange.startDate) {
        params.start_date = this.formatDateParams(this.dateRange.startDate)
      }

      if (this.dateRange.endDate) {
        params.end_date = this.formatDateParams(this.dateRange.endDate)
      }

      return params
    },
  },
  methods: {
    handleDownload() {
      const url = '/v1/ticket-admin/export-claim-retur'
      this.loading = true

      this.$http_komship.get(url, { params: this.params })
        .then(res => {
          this.downloadFromBase64(res.data.data)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal download data claim retur',
              variant: 'danger',
            },
          }, 2000)
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDateParams(d) {
      return moment(d)
        .format('YYYY-MM-DD')
    },
    downloadFromBase64(data) {
      const a = document.createElement('a')
      a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
      a.download = `Claim_Retur_${moment(this.dateRange.startDate).format('DD-MM-YYYY')}_${moment(this.dateRange.endDate).format('DD-MM-YYYY')}.xlsx`
      a.click()
    },
    formatForm() {
      this.actionSelected = ['Nunggu Review']
      this.expedisiSelected = []
      this.dateRange = {
        startDate: last7,
        endDate: today,
      }
    },
    handleBatal() {
      this.formatForm()
      this.$bvModal.hide('modal-download-claim-retur')
    },
  },
}
</script>

<style>
  .modal-download-body {
    padding: 32px 24px !important;
  }

  .table-download-claim-cell {
    padding-right: 12px !important;
    padding-left: 12px !important;
    text-transform: none !important;
  }

  .button-download-claim {
    border-radius: 8px !important;
  }

  @media screen and (max-width: 768px) {
    .dialog-download-claim-retur {
      align-items: start !important;
    }

    body > div.daterangepicker.ltr.show-calendar.show-ranges.openscenter.linked {
      transform: translate(-50%, -50%) !important;
    }
  }
</style>
